<template>
  <div class="welcome-to-vgang">
    <div class="container">
      <div class="row">
        <div class="welcome-message col-sm-12 col-md-8">
          <h1
            class="title mb-3 green--text Polaris-Text--root Polaris-Text--heading4xl"
          >
            Welcome to VGANG!
          </h1>

          <p class="paragraph">
            We're a platform that connects ethical and sustainable product
            suppliers with like-minded retailers. Join us to reach conscious
            consumers and make a difference with every sale.
          </p>

          <button
            class="d-flex Polaris-Button Polaris-Button--primary mb-2"
            type="button"
          >
            <span class="Polaris-Button__Content" @click="OpenWindow">
              <span class="Polaris-Button__Text">Create an account</span>
            </span>
          </button>

          <p class="have-account" @click="OpenWindow">
            Do you already have an account? <a class="blue-text">Sign in</a>
          </p>
        </div>
        <div class="chat-box col-sm-12 col-md-4">
          <div class="chat-container">
            <h3 class="chat-title">Let's Chat!</h3>
            <p class="description">
              Book a Meeting with Us for Onboarding Help or to Get Your
              Questions Answered
            </p>
            <a
              class="blue-text"
              target="_blank"
              href="https://calendly.com/vgang/vgang-for-suppliers"
            >
              Book a Meeting
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";
export default {
  data() {
    return {
      userType: "supplier",
      isShopify: false,
      popup: null,
    };
  },

  mounted() {
    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      this.isShopify = true;
    }

    const oAuthHost = process.env.VUE_APP_OAUTH_HOST;
    window.addEventListener(
      "message",
      async (event) => {
        if (event.origin == oAuthHost) {
          if (event.data.type === "success") {
            this.$router.push("/supplier/assign-categoryV2");
          } else if (event.data.type === "request") {
            const response = await this.initiateAppBridge();

            if (event.data.message === "SignIn AccessToken") {
              this.popup.postMessage(
                {
                  type: "approve",
                  message: "SignIn AccessToken Provided",
                  data: response,
                },
                oAuthHost
              );
            } else if (event.data.message === "SignUp AccessToken") {
              this.popup.postMessage(
                {
                  type: "approve",
                  message: "SignUp AccessToken Provided",
                  data: response,
                },
                oAuthHost
              );
            }
          }
        } else {
          return;
        }
      },
      false
    );
  },
  methods: {
    async initiateAppBridge() {
      const apiKey = process.env.VUE_APP_SHOPIFY_API_KEY;
     const host = await getVariable("host");
       

      let app = createApp({
        apiKey: apiKey,
        host: host,
        // forceRedirect: false,
      });

      try {
        const response = await getSessionToken(app);

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    async OpenWindow() {
      const width = 656;
      const height = 750;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      // const shopifyToken = await getVariable("shopifyToken");
      const oAuthHost = process.env.VUE_APP_OAUTH_HOST;

      const popup = await window.open(
        `${oAuthHost}/supplier/oauth?src=${process.env.VUE_APP_SOURCE}&src_name=${process.env.VUE_APP_SOURCE_NAME}`,
        "_blank",
        `width=${width},height=${height},left=${left},top=${top}`
      );
      this.popup = popup;
    },
  },
};
</script>
<style scoped lang="scss">

