<template>
  <main>
    <div v-if="userType == 'supplier'">
      <div v-if="!loading">
        <v-main app>
          <div class="page-wrapper-supplier">
            <router-view />
          </div>
          <Footer />
        </v-main>
      </div>
      <div class="spinner-container" v-else>
        <PSpinner color="teal" accessibilityLabel="Spinner Example" />
      </div>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-sm-12">
          <div class="no-access">You don't have access to this panel</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SupplierFormWizard from "./supplier/form-wizard/SupplierFormWizard.vue";
import Footer from "../components/supplier/Footer.vue";
import WelcomeToVgang from "@/views/supplier/WelcomeToVgang.vue";
import createApp from "@shopify/app-bridge";
import { TitleBar } from "@shopify/app-bridge/actions";
import { getSessionToken } from "@shopify/app-bridge-utils";
import RegisterationSetup from "@/services/RegisterationSetup";
import AuthService from "@/services/AuthenticationService";
import ValidateService from "@/services/ValidateService";

import {
  setVariable,
  getVariable,
  removeVariable,
} from "@/utils/conditionalStoreVariables";

import CommonData from "@/services/CommonData";

export default {
  components: {
    WelcomeToVgang,
    Footer,
    SupplierFormWizard,
  },
  data() {
    return {
      userType: "supplier",
      loading: null,
      shopifyURL: "",
      showSyncingProgress: false,
      currency_iso: null,
      shop_id: null,
      platform_name: null,
      timestamp: null,
      state: null,
      hmac: null,
    };
  },
  async created() {
    this.loading = true;
    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      if (window.self !== window.top) {
        try {
          const AppBridgeIsSet = await this.initiateAppBridge();

          if (AppBridgeIsSet) {
            const response = await AuthService.getInitialState();

            if (response.status === 200) {
              const data = response.data;
              // TODO Redirect the base on those next use cases
              //  the userConnected == false then show the connect your account button
              if (this.currentRouteName == "SupplierOnboarding") {
                this.$router.push("/supplier/onboarding");
                this.loading = false;
                return;
              }
              if (
                !data.userConnected &&
                this.currentRouteName !== "SupplierOauth"
              ) {
                this.loading = false;

                this.$router.push("/supplier/login");
                return;
              }

              if (
                !data.userConnected &&
                this.currentRouteName == "SupplierOauth"
              ) {
                this.loading = false;

                this.$router.push("/supplier/Oauth");
                return;
              }

              //  if startedImporting == false then show the sync your products button
              if (
                !data.startedImporting &&
                this.currentRouteName == "Supplier"
              ) {
                this.loading = false;

                this.$router.push("/supplier/assign-categoryV2");
                return;
              }
              //  if startedImporting == true and finishedImporting == false then show the syncing in progress page
              if (
                data.startedImporting &&
                !data.finishedImporting &&
                this.currentRouteName == "Supplier"
              ) {
                this.loading = false;

                this.$router.push("/supplier/assign-categoryV2");
                return;
              }
              //  if finishedImporting == true then show the products page
              if (
                data.finishedImporting &&
                this.currentRouteName == "Supplier"
              ) {
                this.loading = false;
                this.$router.push("/supplier/assign-categoryV2");
                return;
              }
            } else {
              this.loading = false;
              this.$router.push("/supplier/login");
              return;
            }
          } else {
            this.loading = false;
          }
        } catch (error) {
          console.log("supplier error ", error);
        }
      } else {
        const params = window.location.href.split("?");
        if (window) {
          window.location.replace(process.env.VUE_APP_SHOPIFY_REDIRECT + params[1]);
          //this.$router.push("/supplier/assign-categoryV2");
        }
        this.loading = false;
      }
    } else {
      if (this.$route.query?.currency_iso) {
        this.currency_iso = this.$route.query.currency_iso;
      } else if (getVariable("currency_iso")) {
        this.currency_iso = getVariable("currency_iso");
      } else {
        this.currency_iso = null;
      }

      if (this.$route.query.shop_id) {
        this.shop_id = this.$route.query.shop_id;
      } else if (getVariable("shop_id")) {
        this.shop_id = getVariable("shop_id");
      } else {
        this.shop_id = null;
      }

      if (this.$route.query.platform_name) {
        this.platform_name = this.$route.query.platform_name;
      } else if (getVariable("platform_name")) {
        this.platform_name = getVariable("platform_name");
      } else {
        this.platform_name = null;
      }

      if (this.$route.query.timestamp) {
        this.timestamp = this.$route.query.timestamp;
      } else if (getVariable("timestamp")) {
        this.timestamp = getVariable("timestamp");
      } else {
        this.timestamp = null;
      }

      if (this.$route.query.state) {
        this.state = this.$route.query.state;
      } else if (getVariable("state")) {
        this.state = getVariable("state");
      } else {
        this.state = null;
      }

      if (this.$route.query.hmac) {
        this.hmac = this.$route.query.hmac;
      } else if (getVariable("hmac")) {
        this.hmac = getVariable("hmac");
      } else {
        this.hmac = null;
      }
      if (this.shop_id && this.platform_name) {
        setVariable("currency_iso", this.currency_iso);
        setVariable("shop_id", this.shop_id);
        setVariable("platform_name", this.platform_name);
        setVariable("timestamp", this.timestamp);
        setVariable("state", this.state);
        setVariable("hmac", this.hmac);

        this.sellerValidate();
      } else {
        this.getInit();
      }
    }
  },
  methods: {
    async initiateAppBridge() {
      const apiKey = process.env.VUE_APP_SHOPIFY_API_KEY;
      const url_string = window.location.href;

      const url = new URL(url_string);
      const host = url.searchParams.get("host");

      // const host = "ZGF2aWRpNy5teXNob3BpZnkuY29tL2FkbWlu";

      /*
       */
      if (
        process.env.VUE_APP_SOURCE_NAME == "shopifyimporter" &&
        window.self !== window.top
      ) {
        setVariable("host", host);
      }

      const app = createApp({
        apiKey: apiKey,
        host: host,
      });

      const titleBarOptions = {
        title: "Vgang supplier",
      };
      TitleBar.create(app, titleBarOptions);
      // console.log(myTitleBar);
      try {
        const response = await getSessionToken(app);

        // const response =
        //   "484b7a1088441e56fe6d187007802ac1b5819927936c8601fe6e015b3805bd10";
        setVariable("shopifyToken", response);

        this.loading = false;
        return true;
      } catch (error) {
        console.log("supplier registration error:", error);
      }
    },
    sellerValidate() {
      const currencyIso = getVariable("currency_iso");
      const shopId = getVariable("shop_id");
      const platformName = getVariable("platform_name");
      const timestamp = getVariable("timestamp");
      const state = getVariable("state");
      const hmac = getVariable("hmac");
      const params = {
        currencyIso: currencyIso ? currencyIso : "",
        storeUrl: shopId ? shopId : "",
        platformName: platformName ? platformName : "",
        timestamp: timestamp ? timestamp : "",
        state: state ? state : "",
        hmac: hmac ? hmac : "",
      };
      ValidateService.sellerValidate(params)
        .then((response) => {
          this.getInit();
          removeVariable("currency_iso");
          removeVariable("shop_id");
          removeVariable("platform_name");
          removeVariable("timestamp");
          removeVariable("state");
          removeVariable("hmac");
          if (response.status == 200) {
            this.SuccessDialog = true;
            this.dialog = false;
            this.validateDialogeMessage = "Shop installed successfully!";
          }
        })
        .catch((e) => {
          this.loading = false;
          this.finalStepErrorMessage = e.response.data.message;
          this.dialog = true;
          this.SuccessDialog = false;
          this.validateDialogeMessage = e.response.data.message;
          removeVariable("currency_iso");
          removeVariable("shop_id");
          removeVariable("platform_name");
          removeVariable("timestamp");
          removeVariable("state");
          removeVariable("hmac");
          this.getInit();
        });
    },
    getInit() {
      RegisterationSetup.getRegisterSetupInit()
        .then(function (response) {
          this.loading = false;

          this.showSyncingProgress = response.data.syncing;
          if (response.data.syncing == true) {
            this.syncingProcess();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    syncingProcess() {
      const self = this;
      RegisterationSetup.getRegisterSetupInit()
        .then((response) => {
          if (response.data.syncing == true) {
            setTimeout(function () {
              self.syncingProcess();
            }, 5000);
          } else if (response.data.syncing == false) {
            if (this.step == "Complete") {
              this.$router.push("/supplier/assign-categoryV2");
            }
          } else if (response.data.step == "Complete") {
            this.$router.push("/supplier/assign-categoryV2");
          } else {
            return;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    CommonData.getCurrentUser()
      .then((response) => {
        this.userType = response.data.role;
        setVariable("role", this.userType);
      })
      .catch((e) => {
        console.log("supplier mounted error:", e);
      });
  },
};
</script>

<style lang="scss">
.spinner-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-access {
  border-radius: 10px;
  text-align: center;
  color: #ff0a43;
  padding: 30px;
  border: 1px solid #ff0a43;
}
</style>
