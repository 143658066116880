<template>
  <footer style="margin-top: 24px">
    <div class="Polaris-FooterHelp np-footer d-flex align-items-center">
      <a href="https://vgang.io/partnership" class="mx-5" target="_blank">
        <span
          class="Polaris-Button__Content Polaris-Button Polaris-Button--plain"
        >
          Learn how VGANG works with Suppliers
          <span class="Polaris-Icon">
            <svg
              viewBox="0 0 20 20"
              class="Polaris-Icon__Svg"
              focusable="false"
              aria-hidden="true"
            >
              <path
                d="M11 4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V6.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L13.586 5H12a1 1 0 0 1-1-1zM3 6.5A1.5 1.5 0 0 1 4.5 5H8a1 1 0 0 1 0 2H5v8h8v-3a1 1 0 1 1 2 0v3.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 3 15.5v-9z"
              ></path>
            </svg>
          </span>
        </span>
      </a>
      <a href="https://vgang.io/terms" class="mx-5" target="_blank">
        <span
          class="Polaris-Button__Content Polaris-Button Polaris-Button--plain"
          >Terms & Conditions<span class="Polaris-Icon"
            ><svg
              viewBox="0 0 20 20"
              class="Polaris-Icon__Svg"
              focusable="false"
              aria-hidden="true"
            >
              <path
                d="M11 4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V6.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L13.586 5H12a1 1 0 0 1-1-1zM3 6.5A1.5 1.5 0 0 1 4.5 5H8a1 1 0 0 1 0 2H5v8h8v-3a1 1 0 1 1 2 0v3.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 3 15.5v-9z"
              ></path></svg></span></span
      ></a>

      <div class="Polaris-FooterHelp__Content ml-auto mr-5 noFee">
        <div class="Polaris-FooterHelp__Icon">
          <span
            class="Polaris-Icon Polaris-Icon--colorHighlight Polaris-Icon--applyColor"
            ><svg
              viewBox="0 0 20 20"
              class="Polaris-Icon__Svg"
              focusable="false"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-9 3a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2zm0-6a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"
              ></path></svg
          ></span>
        </div>
        <div class="Polaris-FooterHelp__Text">
          VGANG for suppliers of free, we are not charging any commission or
          hidden fee
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
